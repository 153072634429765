.elrichwrapper {
  
}

.elrichtoolbar {
  
}

.elricheditor {
  border: 1px solid #b5b5b5;
  box-shadow: inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05);
  border-radius: 4px;
  padding-bottom: calc(0.5em - 1px);
  padding-left: calc(0.75em - 1px);
  padding-right: calc(0.75em - 1px);
  padding-top: calc(0.5em - 1px);
}