.table-applications {
    margin-top: 1em;
}

.table-applications .even {
    background-color:#f3f2f1;
    border: 0px;
}

.table-applications .odd {
    background-color: rgb(236, 236, 236);
    border: 0px;
}

.table-applications td {
    border: 0px
}