.table-activities {
    margin-top: 1em;
}

.table-activities .even {
    background-color:#f3f2f1;
    border: 0px;
}

.table-activities .odd {
    background-color: rgb(236, 236, 236);
    border: 0px;
}

.table-activities td {
    border: 0px
}

.table-activities ol, .table-activities li {
    padding-left: 1em;
}

.bullets {
    display: list-item;
    margin-left: 10pt;
}

.bullets li {
    list-style-type: disc;
}
