.dayInput {
    width: 18px;
    margin: auto auto 10px auto;
}

.clientTable .clientHeader {
    min-width: 200px;
}

.clientTable .dayHeader {
    text-align: center;
    padding: 0px;
    padding-top: 5px;
    margin: 0px;
    font-size: 12px;
    font-weight: bold;
}

.clientHeader {
    font-weight: bold;
    vertical-align: top!important;
}

.clientTable tbody tr input {
    display: block;
}

.isFreeDay {
    background-color: rgb(39, 108, 218);
}