.uploadModalBox {
}


.uploadModalBox p {
  margin-bottom: 1em;
}

.uploadModalBox button {
  margin-bottom: 1em;
}

.uploadModalBox .image-container {
  margin-bottom: 1em;
}

.uploadModalBox .image-container h3 {
  margin-bottom: 0.4em;
}

.uploadModalBox .image-container img {
  border: gray dashed 1px;
}