.dayInput {
    width: 18px;
    margin: auto auto 10px auto;
}

.clientTable .clientHeader {
    min-width: 200px;
}

.clientTable th {
    text-align: left;
}

.clientTable th.day {
    text-align: center;
}

.clientTable tbody tr input {
    display: block;
}

.isFreeDay {
    background-color: rgb(39, 108, 218);
}