html,
#root {
    margin: 0;
    padding: 0;
    font-family: "Segoe UI Web (West European)", "Segoe UI", -apple-system,
        BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px;
    line-height: 1.4286;
    height: 100%;
}

.headerButton {
    margin-left: 13px;
}

@media (max-width: 768px) {

    html,
    #root {
        font-size: 14px;
        line-height: 1.2286;
    }
}

.react-datepicker-popper {
    z-index: 100 !important;
}

.navbar {
    margin-bottom: 1em;
}

.dropdownmargin {
    margin-bottom: 20px !important;
}

.navbar-item img {
    margin-left: 40px;
}

.previewPicture {
    max-width: 150px;
}

.table {
    margin-top: 1.5em;
}

.table td {
    vertical-align: middle;
}

table td .button {
    margin-left: 12px;
}

table td.actions {
    text-align: right;
}

.section {
    padding-top: 1em;
}

.defaultSection {
    /*padding-top: 2rem;*/
    background-color: white;
}

.textarea {
    min-height: 11em !important;
}

.defaultSection h2 {
    /* color: red; */
    font-size: 1.2em;
}

.button.is-primary {
    /*background-color: var(--color-primary);*/
}

.button.is-primary:hover {
    background-color: black;
}

.button.is-danger:hover {
    background-color: black;
}

.checkbox {
    margin-right: 24px;
}

.projectnumbersselect .select {
    width: auto;
}

.selectFilter .select {
    width: auto;
}

hr {
    margin-top: 4px;
    margin-bottom: 4px;
}

.selectionButton {
    margin-bottom: 8px;
}

.infoDetails {
    padding: 16px;
    /*background-color: #F0C02D;*/
    background-color: #F9F8F7;
    border-radius: 8px;
    margin-bottom: 1em;
}

.is-danger.input {
    border-color: red;
    border-width: 1px;
}

.help.is-danger {
    padding: 4px;
    margin-top: 8px;
    background-color: #E3982F;
    color: #4E4943;
    font-weight: 700;
    border-radius: 4px;
    display: block;
}

th.active .arrow {
    opacity: 1;
}

.arrow {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 0;
    margin-left: 5px;
    opacity: 0.66;
}

.arrow.ascending {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-bottom: 4px solid #42b983;
}

.arrow.descending {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 4px solid #42b983;
}

.dropzone {
    font-family: sans-serif;
    text-align: center;
    padding: 20px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    margin-top: 16px;
}

.navbar {
    background-color: white;
    background: white;
}

.navbar {
    border-bottom-width: 15px;
    border-bottom-style: solid;
}

.navbar-item {
    font-weight: bold;
}

.navbar-item img {
    max-height: 4.5em;
}

.table-activities,
.table-applications {}

.date-activities {
    margin-bottom: 12px;
}

.clientImage {
    max-width: 150px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 4px;
}

.photoBox {
    margin-bottom: 4em;
}

.noData {
    height: 40px;
    padding: 8px;
    text-align: center;
    background-color: floralwhite;
    margin-bottom: 12px;
}

.activitiesbar {
    margin-bottom: 0px !important;
    padding-bottom: 0;
}

.clientDetailsButtons {
    margin-top: 6em;
}

.picker-container .field {
    margin-bottom: 0;
}

.picker-container-list {
    z-index: 99999;
    position: fixed;
    background-color: white;
    padding: 12px;
    cursor: pointer;
    /*width: 100%;*/
    word-wrap: break-word;
}

.sortButton {
    background-color: white;
}

.tableEmployees {
    margin-left: 20px;
    padding-left: 0px;
}

.employeeAdmin {
    margin-left: 10px;
}

.backAndSaveButton {
    margin-left: 10px;
}

.downloadTableButton {
    background-color: white;
    border-radius: 2px;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    margin-bottom: 0.5rem;
    font-weight: 700;
}

.picker-container-secondary {
    font-weight: lighter;
    font-size: 0.85em;
}