.fabricIcon {
  vertical-align: 'middle';
  max-height: 16px;
  max-width: 16px;
}

.iconColumn {
  min-width: 16px;
  min-height: 16px;
}

.tdfullwidth {
  width: 100%;
}