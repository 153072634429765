.pagination {
  margin-top: 1em;
}

.pagination .goButtons button {
  margin: 0px;
}

.noPadding {
  padding: 0px !important;
}

table .pagingtop th {
  margin: 0px;
  padding-bottom: 1.5em;
}